import React from "react";
import { Link } from "gatsby";
import { Layout, SEO } from '../../components/structure';
import { Content } from "../../components/blocks"
import styled from "styled-components";
import { brand, font, mq } from "../../styles";

const Header = styled.div`


${ font.imports.sherika }

  width: 90%;
  max-width: 1100px;
  text-align: left;
  padding-top 80px;

  h2 {
    font-family: ${font.family.sherika};
    font-size: 56px;
    font-weight: 700;
    padding: 20px 0;
    color: ${brand.colors.pg_primary_dark};
    display: inline-block;

    .color-emphasis {
      color: ${brand.colors.pg_orange};
    }
  }

  span {
    display: inline-block;
    color: ${brand.colors.pg_primary_dark};
  }

  .date {
    display: block;
    font-family: ${font.family.sherika};
    font-weight: 700;
    font-size: 16px;
    margin: 30px 0;
  }

  p {
    color: ${brand.colors.pg_primary_dark};
    font-size: 14px;
    line-height: 21px;

    a {
      display: inline-block;
      color: ${brand.colors.pg_blue};
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

const Subsection = styled.div`
  width: 90%;
  max-width: 1100px;
  text-align: left;
  padding-bottom: 40px;

  h5 {
    font-size: 24px;
    font-weight: 600;
    color: ${brand.colors.pg_orange};
    padding: 20px 0;

    &:last-of-type {
      padding-bottom: 10px;
      margin-bottom: 0;
    }
  }

  p {
    color: ${brand.colors.pg_primary_dark};
    font-size: 16px;
    line-height: 21px;

    a {
      display: block;
      color: ${brand.colors.pg_blue};
      cursor: pointer;
      text-decoration: underline;
    }
  }

  ul {
    li {
      list-style-type: disc;
      color: ${brand.colors.pg_primary_dark};
      a {
        display: block;
        color: ${brand.colors.pg_blue};
        cursor: pointer;
        text-decoration: underline;
        font-size: 16px;
        line-height: 21px;
      }
    }
  }
`;

const TableDetails = styled.table`
display: block;
  color: ${brand.colors.pg_primary_dark};
  font-family: ${font.family.sherika};
  font-size: 20px;
    thead
      tr {
        td {
          color: ${brand.colors.pg_primary_dark};
          border-top: 1px solid hsla(0,0%,0%,0.12);
          font-weight: 600;
        }
    }

    tbody {
      tr {
        td {
          vertical-align: top;
          font-weight: 400;

          a {
            color: ${brand.colors.pg_orange};
            text-decoration: underline;
          }
        }
        td:first-of-type {
          font-weight: 700;
        }
        
      }
    }

    @media only screen and ${mq.maxMd} {
      display: none;
    }
`;

const TableDetailsMobile = styled.div`
    display: none;

    div {
      margin: 20px 0;
      span {
        color: ${brand.colors.pg_primary_dark};
        font-family: ${font.family.sherika};
        font-size: 20px;
        display: block;
        margin: 10px 0;
      }

      .purpose-con, .optout-con {
        font-family: ${font.family.sherika};
        font-size: 16px;

        a {
          text-decoration: underline;
          color: ${brand.colors.pg_orange};
        }
      }
    }

    @media only screen and ${mq.maxMd} {
      display: block;
    }
`;

const CookiePolicy = styled.div`
    width: 95%;
    max-width: 1100px;
    margin: auto;
`

const GDPRPage = () => (
  <Layout>
    <SEO title="Cookie Policy | Plusgrade" 
          description={'Plusgrade Cookie Policy'}
    />
    <Content whiteBG e2e>
      <CookiePolicy>
        <Header>
          <h2>Plusgrade <span className={"color-emphasis"}>Cookie Policy</span></h2>
          
          <span className={"date"}>Last Updated May 24, 2018</span>

          <p>
            This Cookie Policy (“<strong>Policy</strong>”) describes the cookies that Plusgrade uses on its website (“<strong>Website</strong>”) and the customer platform (“<strong>Platform</strong>”), and the choices that you have. This Cookie Policy forms part of our Privacy Policy. Please refer to the <Link to={'/policy/privacy/'}>Privacy Policy</Link> for information about (i) the purposes for which we use your personal data and our lawful basis for processing it, (ii) how we protect your data, and (iii) your rights and our obligations in relation to such use.
          </p>

          <p>
          By choosing to use our Website or our Platform after having been notified of our use of cookies in the ways described in this Privacy Policy, and, in applicable jurisdictions, through notice and unambiguous acknowledgement of your consent, you agree to such use.
          </p>
        </Header>
        <Subsection>
          <h5>What Are Cookies?</h5>
          <p>
            A “cookie” is a piece of information sent to your browser by a website you visit. We use cookies to make it easier for you to use our services during future visits and monitor traffic on our Website and Platform. To find out more about cookies visit <a style={{"display": "inline-block"}} href="http://www.allaboutcookies.org/">www.allaboutcookies.org</a>.
          </p>
          <p>
            Some cookies expire after a certain amount of time, or upon logging out (session cookies), others remain on your computer or device for a longer period (persistent cookies). We use first party cookies (cookies set directly by Plusgrade) as well as third-party cookies, as described below.
          </p>

          <h5>How Is Plusgrade Using Cookies?</h5>

          <TableDetails>
          <colgroup>
            <col span="1" style={{"width": "10%"}} />
            <col span="1" style={{"width": "60%"}} />
            <col span="1" style={{"width": "30%"}} />
          </colgroup>
            <thead>
              <tr>
                <td>Type</td>
                <td>Purpose</td>
                <td>Opt-Out</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Strictly Necessary</td>
                <td>
                  We use cookies that are strictly necessary to provide users with the services available through our Website, the Platform and to use some of their features, such as the ability to log-in and access to secure areas. In particular, we use cookies to enable connectivity to the Plusgrade offer process application, loading of images and content, as well as allowing the customer to submit offers on the Platform. We also use a cookie necessary to record a user’s consent to the use of cookies as described in this Cookie Policy.
                </td>
                <td>
                  Because these cookies are strictly necessary, you cannot refuse them.
                </td>
              </tr>
              <tr>
                <td>Analytics / Performance</td>
                <td>
                  <strong>Google Analytics:</strong> Used for the purpose of traffic performance, customer interaction with the Website and the Platform, and usage patterns. Your IP address and other information collected by this cookie will be sent to and stored by Google on servers in the United States.  <a href="https://policies.google.com/technologies/partner-sites?hl=en-GB&gl=uk/">Click here</a> to learn more. <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">Click here</a> to learn more on expiration of these cookies.
                  <br></br>
                  <strong>Plusgrade:</strong> We set a cookie when you use the Platform to help assist with customer support, customer usage, and customer transaction tracking. The cookie will expire after 365 days.
                </td>
                <td>
                  You can block or delete cookies by changing the browser settings as explained under the “Your Choices” section below. You can also prevent your data from being collected by Google Analytics on our Website and the Platform by using the <a href="https://tools.google.com/dlpage/gaoptout?hl=en">Google Analytics Opt-out Browser Add-on</a> for your current web browser.
                </td>
              </tr>
              <tr>
                <td>Functionality</td>
                <td>
                  Used to provide additional functionality to our Website and the Platform or to gather additional information about your use of our Website and the Platform:
                  <br></br>
                  <strong>New Relic:</strong> Used on our Platform for the purposes of systems performance monitoring, network latency monitoring, application load and response times, and browser compatibility analysis. The cookie expires when the browser/session is closed.
                  <br></br>
                  <strong>Plusgrade:</strong> Used for Website navigation, content delivery, or other functional needs.
                </td>
                <td>
                  You can block or delete these cookies by changing the browser settings as explained under the “Your Choices” section below.
                </td>
              </tr>
            </tbody>
          </TableDetails>

          <TableDetailsMobile>
            <div className="cookie-type-con">
              <span className="type-con">
                Strictly Necessary
              </span>
              <span className="purpose-con">
                We use cookies that are strictly necessary to provide users with the services available through our Website, the Platform and to use some of their features, such as the ability to log-in and access to secure areas. In particular, we use cookies to enable connectivity to the Plusgrade offer process application, loading of images and content, as well as allowing the customer to submit offers on the Platform. We also use a cookie necessary to record a user’s consent to the use of cookies as described in this Cookie Policy.
              </span>
              <span className="optout-con">
                <strong>Opt Out: </strong>Because these cookies are strictly necessary, you cannot refuse them.
              </span>
            </div>

            <div className="cookie-type-con">
              <span className="type-con">
              Analytics / Performance
              </span>
              <span className="purpose-con">
                  Used to provide additional functionality to our Website and the Platform or to gather additional information about your use of our Website and the Platform:
                  <br></br>
                  <strong>New Relic:</strong> Used on our Platform for the purposes of systems performance monitoring, network latency monitoring, application load and response times, and browser compatibility analysis. The cookie expires when the browser/session is closed.
                  <br></br>
                  <strong>Plusgrade:</strong> Used for Website navigation, content delivery, or other functional needs.
              </span>
              <span className="optout-con">
                <strong>Opt Out: </strong>You can block or delete cookies by changing the browser settings as explained under the “Your Choices” section below. You can also prevent your data from being collected by Google Analytics on our Website and the Platform by using the <a href="https://tools.google.com/dlpage/gaoptout?hl=en">Google Analytics Opt-out Browser Add-on</a> for your current web browser.
              </span>
            </div>

            <div className="cookie-type-con">
              <span className="type-con">
              Functionality
              </span>
              <span className="purpose-con">
                <strong>Google Analytics:</strong> Used for the purpose of traffic performance, customer interaction with the Website and the Platform, and usage patterns. Your IP address and other information collected by this cookie will be sent to and stored by Google on servers in the United States.  <a href="https://policies.google.com/technologies/partner-sites?hl=en-GB&gl=uk/">Click here</a> to learn more. <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">Click here</a> to learn more on expiration of these cookies.
                    <br></br>
                <strong>Plusgrade:</strong> We set a cookie when you use the Platform to help assist with customer support, customer usage, and customer transaction tracking. The cookie will expire after 365 days.
              </span>
              <span className="optout-con">
                <strong>Opt Out: </strong>You can block or delete these cookies by changing the browser settings as explained under the “Your Choices” section below.
              </span>
            </div>
            
          </TableDetailsMobile>

          <h5>Your Choices</h5>
          <p>
            Please note that if you limit the ability of websites to set cookies, you may be unable to access certain parts of the Website or the Platform and you may not be able to benefit from the full functionality of the Website or the Platform.
          </p>
          <p>
            On most web browsers, you will find a “help” section on the toolbar. Please refer to this section for information on how to receive a notification when you are receiving a new cookie and how to turn cookies off. Please see the links below for guidance on how to modify your web browser’s settings on the most popular browsers:
          </p>
          <ul>
          <li> <a href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-11">Internet Explorer</a></li>
          <li> <a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?esab=a&s=cookies&r=6&as=s&redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US">Mozilla Firefox</a></li>
          <li> <a href="https://support.google.com/accounts/answer/61416?hl=en">Google Chrome</a></li>
          <li> <a href="https://support.apple.com/en-ca/guide/deployment/depf7d5714d4/web">Apple Safari</a></li>
          </ul>
          <p>
            If you access the Website or the Platform on your mobile device, you may not be able to control tracking technologies through the settings.
          </p>
          <h5>Changes</h5>
          <p>
            We may change the type of third party service providers that place cookies and amend this Cookie Policy at any time by posting the amended version on the Website or the Platform. Unless additional notice or consent is required by applicable laws, this will serve as your notification of these changes.
          </p>
          <h5>Contact Us</h5>
          <p>
            If you have any questions or concerns, please contact <a style={{"display": "inline-block"}} href={"mailto:dpo@plusgrade.com"}>dpo@plusgrade.com</a>.
          </p>
        </Subsection>
      </CookiePolicy>
    </Content>
  </Layout>
);




export default GDPRPage;
